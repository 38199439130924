<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Team permission" icon="user-shield" />

    <div class="generalBox border shadow-sm">
        <div class="row">
           
            <div class="col-12 mb-4">
                <table width="100%" border="0">
                    <tr>
                        <td width="30px">
                            <Popper class="popperDark" arrow hover content="Back to Team">
                                <fa icon="angle-left" v-if="!showEditMode" size="lg" class="isLink" @click="$router.push({ path: '/team' })" />
                            </Popper>
                        </td>
                        <td>
                            <div class="input-group">
                                <span class="input-group-text"><fa icon="users" /></span>
                                <select v-model="teamId" class="form-select" @change="getTeamPerm">
                                    <option :value="t.teamId" v-for="t in arrTeam" :key="t.teamId">{{ t.teamName }}</option>
                                </select>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>

            <div class="col-12">
                <table class="table">
                    <thead>
                        <tr class="fw-bold">
                            <td>
                                <div class="row">
                                    <div class="col-5 col-lg-2">Category</div>
                                    <div class="col-7 col-lg-2">Menu</div>
                                    <div class="col-lg-8">Security group</div>
                                </div>
                            </td>
                        </tr>
                    </thead>   
                    <tbody v-for="(menus, cat) in arrPermOpt" :key="cat">
                        <tr v-for="(menu, menuIdx) in menus" :key="menu">
                            <td>
                                <div class="row">
                                    <div class="col-5 col-lg-2 d-none d-lg-block">
                                        <span v-if="menuIdx === 0" class="fw-bold">{{ cat }}</span>
                                    </div>
                                    <div class="col-5 col-lg-2 d-lg-none fw-bold">{{ cat }}</div>
                                    <div class="col-7 col-lg-2">{{ menu.menu }}</div>
                                    <div class="col-lg-8">

                                        <div class="row mt-1" v-for="p in menu.jPermission" :key="p">
                                            <div class="col-10 col-lg-5 mb-2">{{ p.name }}
                                                <!-- <span class="small text-warning">{{p.id}}</span> -->
                                            </div>
                                            <div class="col-2 col-lg-1">
                                                <input type="checkbox" v-model="arrPermChecked" :value="p.id" class="form-check-input" />
                                            </div>
                                            <div class="col-6 col-lg-3 text-end">
                                                <Popper class="popperDark" arrow hover content="Team">
                                                    <span class="badge bg-grey isTooltipsLabel me-1"><fa icon="users" /> {{ p.totalTeam }}</span>
                                                </Popper>
                                                <span class="ps-2 isLink" data-bs-toggle="modal" data-bs-target="#mdlView" @click="showTeamList(p)">view</span>
                                            </div>
                                            <div class="col-6 col-lg-3 text-end mb-2">
                                                <Popper class="popperDark" arrow hover content="Member">
                                                    <span class="badge bg-grey isTooltipsLabel me-1"><fa icon="user" /> {{ p.totalMember }}</span>
                                                </Popper>
                                                <span class="ps-2 isLink" data-bs-toggle="modal" data-bs-target="#mdlView" @click="showMemberList(p)">view</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>    
                </table>
            </div>

            <div class="col-12 pt-3 text-center">
                <button class="btn btn-outline-secondary me-2" @click="$router.push({ path: '/team' })">
                    <fa icon="angle-left" size="lg" class="me-2" />Back
                </button>
                <button @click="updPermission" class="btn btn-secondary">
                    <fa icon="floppy-disk" size="lg" class="me-2" />Update
                </button>
            </div>
        </div>
    </div>

    <!-- Modal --> 
    <div
        class="modal fade"
        id="mdlView"
        tabindex="-1"
        aria-labelledby="TeamMbr"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">{{menuName}}</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <div class="mb-2">
                        <div v-if="mdlList === 'team'">
                            <div class="table-responsive" style="height: 300px">
                                <table class="table table-hover" border="0">
                                    <tbody v-if="arrTeamList">
                                        <tr v-for="(t, tIdx) in arrTeamList" :key="t" data-bs-dismiss="modal">
                                            <td>
                                                <div>
                                                    <span class="me-2"><fa icon="users" /></span>
                                                    <span>{{t.teamName}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div v-else>
                            <div class="table-responsive" style="height: 300px">
                                <table class="table table-hover" border="0">
                                    <tbody v-if="arrMbrList">
                                        <tr v-for="(m, mIdx) in arrMbrList" :key="m" data-bs-dismiss="modal">
                                            <td>
                                                <div>
                                                    <table width="100%" border="0">
                                                        <tr>
                                                            <td rowspan="2" width="60px" class="text-start pe-1">
                                                                <ImgAvatar :isUser="true" :id="m.jAvatar.userId" displayName="" :isVerified="m.isVerified" :isActive="m.userStatus" privilege="" :key="mIdx" align="left" width="30" height="30" />
                                                            </td>
                                                            <td>{{ m.displayName }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="small text-grey">{{ m.userId }}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <!-- Modal End -->
</template>

<script>
import { ref, inject, onMounted } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
import ImgAvatar from '@/components/ImgAvatar.vue'

export default {
    name: 'TeamPermission2',
    components: { Alert, TopNavigation, Popper, ImgAvatar },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const arrTeam = ref([])
        const arrPermOpt = ref([])
        const arrPermChecked = ref([])
        const teamId = ref(null)
        const menuName = ref(null)

        const mdlList = ref(null)   // team / member
        const arrTeamList = ref([])
        const arrMbrList = ref([])

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const showTeamList = (data) => {
            mdlList.value = 'team'
            menuName.value = data.name + " :: Team"
            arrTeamList.value = data.jTeam
            console.info('team', JSON.stringify(data))
        }

        const showMemberList = (data) => {
            mdlList.value = 'member'
            menuName.value = data.name + " :: Member"
            arrMbrList.value = data.jTeamMember
        }

        const updPermission = () => {
            const p = {
                jPermission: arrPermChecked.value
            }

            axios.put('/entity/team/' + teamId.value + '/permission', p)
                .then((res) => {

                    if (res.data.status === 1) {
                        getTeamPerm()

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Permission is updated."
                        })

                    } else {
                        func.addLog('teamPermission', 'updPermission', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('teamPermission', 'updPermission - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const getTeamList = async () => {
            await axios.post('/entity/team/list', {})
                .then((res) => {

                    if (res.data.status === 1) {
                        arrTeam.value = res.data.data

                    } else {
                        func.addLog('teamPermission', 'getTeamList', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('teamPermission', 'getTeamList - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const getTeamPerm = async () => {
            arrPermChecked.value = []

            await axios.get('/entity/team/' + teamId.value + '/permission/list', {})
                .then((res) => {
                    if (res.data.status === 1) {
                        arrPermOpt.value = res.data.data

                    } else {
                        func.addLog('teamPermission', 'getTeamPerm', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('teamPermission', 'getTeamPerm - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

                

            if (arrPermOpt.value.MAIN !== undefined) {
                arrPermOpt.value.MAIN.forEach((sec, index) => {
                    if (sec.jPermission !== undefined) {
                        for (var i = 0; i < sec.jPermission.length; i++) {
                            if (sec.jPermission[i].canAccess === true) {
                                arrPermChecked.value.push(sec.jPermission[i].id)
                            }
                        }
                    }
                })
            }

            if (arrPermOpt.value.TOOL !== undefined) {
                arrPermOpt.value.TOOL.forEach((sec, index) => {
                    if (sec.jPermission !== undefined) {
                        for (var i = 0; i < sec.jPermission.length; i++) {
                            if (sec.jPermission[i].canAccess === true) {
                                arrPermChecked.value.push(sec.jPermission[i].id)
                            }
                        }
                    }
                })
            }

            if (arrPermOpt.value['BACK OFFICE'] !== undefined) {
                arrPermOpt.value['BACK OFFICE'].forEach((sec, index) => {
                    if (sec.jPermission !== undefined) {
                        for (var i = 0; i < sec.jPermission.length; i++) {
                            if (sec.jPermission[i].canAccess === true) {
                                arrPermChecked.value.push(sec.jPermission[i].id)
                            }
                        }
                    }
                })
            }
        }

        onMounted(() => {
            teamId.value = route.params.teamId
            getTeamPerm()
            getTeamList()

        })

        return {
            alert, closeAlert, route, router, func, store, 
            teamId, menuName, arrTeam, arrPermOpt, arrPermChecked, 
            updPermission, getTeamPerm, showTeamList, showMemberList,
            mdlList, arrTeamList, arrMbrList
        }

    }
}
</script>

<style>

</style>